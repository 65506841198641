<template>
  <div class="dialog-background">
    <v-dialog
      :value="true"
      persistent
      :overlay-opacity="0.95"
      overlay-color="white"
      max-width="700"
    >
      <CompanyBillingInfo />
    </v-dialog>
  </div>
</template>

<script>
import CREATE_COMPANY_BILLING from '@/modules/auth/Register/queries/createCompanyBilling.gql'
import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import CompanyBillingInfo from '@/modules/payment/CompanyBillingInfo.vue'
import { ProductType } from '@/modules/productPackages/enums/ProductType'
export default {
  components: { CompanyBillingInfo },
  data () {
    return {
      textTimeout: null
    }
  },
  computed: {
    hasActivePackage () {
      return !!this.productType
    },
    productType () {
      return this.$auth?.user?.productType
    },
    productPackage () {
      return getProductPackageQuery()
    }
  },
  mounted () {
    this.$emit('isBillingInProgress')
    this.textTimeout = setTimeout(() => {
      if (this.hasActivePackage) {
        showSnackbarMessage('success', this.$t('alerts.payment.package-update.success'))
        this.$emit('completed')
        return
      }
      this.createCompanyBilling()
    }, 6000)
  },
  unmounted () {
    if (this.textTimeout) {
      clearTimeout(this.textTimeout)
    }
  },
  methods: {
    async createCompanyBilling () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_COMPANY_BILLING,
          variables: {
            input: {
              companyName: this.productPackage.companyName ?? '',
              productType: this.productPackage.productType,
              components: this.productPackage.readableIds ?? [],
              isYearly: this.productPackage.productType === ProductType.BEGINNER ? false : this.productPackage.isYearly
            }
          }
        })

        if (data.createCompanyBilling.stepName) {
          sessionStorage.setItem('registrationFlowNextStep', JSON.stringify(data.createCompanyBilling.stepName))
        }

        if (this.productPackage.productType === ProductType.BEGINNER) {
          return this.$router.push({ name: 'product-finder' })
        }

        this.$emit('completed')
      } catch (error) {
        showSnackbarMessage('error', this.$t('alerts.payment.company-billing.error'))
        this.$router.push({ name: 'product-packages' })
      }
    }
  }
}
</script>
