<!-- eslint-disable vue/no-v-html -->
<template>
  <v-row class="text-left pd-md-0 d-flex justify-center">
    <Loading
      v-if="!isPlatformMarkero && isState('finalize')"
      :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'630px'}"
      @completed="handleCompletedLoading"
    />

    <CompanyBilling
      v-if="!isAbortPaymentCallback && isPlatformMarkero && isState('finalize')"
      @isBillingInProgress="$emit('isBillingInProgress')"
      @completed="handleCompletedLoading"
    />

    <div
      v-if="!isPlatformMarkero && isState('planSelection')"
      :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'1200px'}"
    >
      <div class="px-4 text-center">
        <h1 class="display-1 font-weight-black mb-4">
          {{ $t('billwerk.subscribe.headline', [this.$auth.user.name]) }}
        </h1>
        <p class="mt-n2 text-subtitle-2">
          {{ $t('billwerk.subscribe.subheadline') }}
        </p>
      </div>

      <Products
        :country-code="customer.address.country"
        @productSelected="handleBookableProductSelected"
      />
    </div>

    <div
      v-if="isState('customerForm')"
      :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'830px'}"
    >
      <div
        v-if="!isFormOnly"
        class="px-8 text-center"
      >
        <h1 class="display-1 font-weight-black mb-4">
          {{ $t('billwerk.customer-form.headline', [$auth.user.name]) }}
        </h1>

        <p class="text-body-2 mb-8">
          {{ $t('billwerk.customer-form.subheadline') }}
        </p>
      </div>

      <v-row class="mx-4 mb-2">
        <v-col
          v-if="!isFormOnly"
          cols="12"
          md="6"
          order-md="2"
        >
          <Cart
            :country-code="customer.address.country"
            :product-name="productName"
            :is-yearly-preselected="isYearly"
            @priceListIdChange="handlePriceListIdChange"
            @planVariantIdChange="handlePlanVariantIdChange"
          />
        </v-col>

        <v-col
          ref="formSection"
          cols="12"
          :md="isFormOnly ? 12: 6"
        >
          <CustomerForm
            v-if="isState('customerForm')"
            ref="customerForm"
            v-model="customer"
            :hide-submit="hideSubmit"
            :payment-methods="paymentMethods"
            :show-is-company="true"
            :preview-order="previewOrder"
            :show-country="showCountry"
            :show-coupon-field="showCoupon"
            :is-submitting="isState('customerForm.submitting')"
            :has-payment-provider="hasPaymentProvider"
            @update-preview="getPreviewOrder"
            @submit="handleCustomerFormSubmit"
          />

          <v-alert
            v-if="isState('customerForm.error')"
            class="mt-8"
            type="error"
            text
          >
            <template v-if="errorCode === 'INVALID_COUPON_CODE'">
              <div v-html="$t('billwerk.customer-form.errors.voucher')" /><br>
            </template>

            <template v-else-if="errorCode === 'INVALID_VAT_ID'">
              <div v-html="$t('billwerk.customer-form.errors.vat-id')" /><br>
            </template>

            <template v-else>
              <div v-html="$t('billwerk.customer-form.errors.general')" />
            </template>
          </v-alert>
        </v-col>
      </v-row>
    </div>
  </v-row>
</template>

<script>
import { scrollIntoViewWithOffset } from '@/lib/scrollIntoView'
import stateMixin from '@/mixins/state'
import featureMixin from '@/mixins/feature'
import subscriptionJsMixin from '../subscriptionJsMixin'
import Cart from './Cart.vue'
import CustomerForm from './CustomerForm.vue'
import Products from '@/components/Products.vue'
import GET_ONBOARDING_BILLING_DATA from './queries/getOnboardingBillingData.gql'
import HANDLE_ONBOARDING_BILLING_DATA from './queries/handleOnboardingBillingData.gql'
import ASSIGN_ONBOARDING_BILLING_ORDER_ID from './queries/assignOnboardingBillingOrderId.gql'
import trackingEvents from '@/lib/trackingEvents'
import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'
import GET_ORDER_FOR_COMPANY from './queries/getOrderForCompany.gql'
import Loading from '@/modules/registration/Loading.vue'
import { ProductType } from '@/lib/productTypes'
import { CurrencySymbol } from '@/modules/productPackages/enums/CurrencySymbol'
import brandingMixin from '@/mixins/branding'
import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'
import CompanyBilling from '@/modules/payment/CompanyBilling.vue'

/**
 * URL query parameter for the payment callback.
 * Will be appended to the URL which is passed to the payment provider.
 */
const PAYMENT_CALLBACK_PARAM = 'paymentCallback'

const FALLBACK_PAYMENT_METHOD = 'CreditCard:Reepay'

export default {
  components: {
    CustomerForm,
    Cart,
    Products,
    Loading,
    CompanyBilling
  },

  inject: ['billingOnboardingDetailsData'],

  mixins: [
    brandingMixin,
    featureMixin,
    stateMixin,
    subscriptionJsMixin
  ],

  props: {
    countryCode: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      default: ''
    },
    readableIds: {
      type: Array,
      default: null
    },
    email: {
      type: String,
      required: true
    },
    isFormOnly: {
      type: Boolean,
      default: false
    },
    showCountry: {
      type: Boolean,
      default: false
    },
    showCoupon: {
      type: Boolean,
      default: false
    },
    couponCode: {
      type: String,
      default: ''
    },
    hideSubmit: {
      type: Boolean,
      default: false
    },
    hasPaymentProvider: {
      type: Boolean,
      default: false
    }
  },

  unmounted () {
    clearTimeout(this.responseTimeout)
    clearTimeout(this.informationTimeout)
    clearTimeout(this.animationTimeout)
  },

  data () {
    return {
      previewOrder: null,
      markeroBillingState: 'idle',
      signupService: null,
      portalService: null,
      paymentService: null,
      initialState: this.isFormOnly ? 'customerForm' : 'planSelection',
      errorCode: null,
      responseTimeout: null,
      informationTimeout: null,
      animationTimeout: null,

      /**
       * This object will be passed as is to SubscriptionJS.
       */
      cart: {
        priceListId: '',
        planVariantId: ''
      },

      /**
       * This object will be passed as is to SubscriptionJS.
       */
      customer: {
        emailAddress: this.email,
        firstName: this.$auth.user.given_name,
        lastName: this.$auth.user.family_name,
        companyName: '',
        vatId: '',
        // belongs to this.cart object, but it's collected in the customer form
        couponCode: '',
        // Doesn't belong to customer object, but it's collected in the customer form.
        // Should be moved when handled differently.
        paymentMethod: '',
        address: {
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          country: this.countryCode?.toUpperCase()
        }
      },

      /** Order object from Billwerk */
      order: null,

      productName: true,
      isYearly: true
    }
  },

  computed: {
    hasActivePackage () {
      return !!this.$auth?.user?.productType
    },
    isProductTypeBeginner () {
      return this.productType === ProductType.BEGINNER
    },
    productPackageQuery () {
      return {
        readableIds: this.readableIds,
        productType: this.productType
      }
    },
    currency () {
      const currencyMap = {
        [CurrencySymbol.SWISSFRANC]: 'CH',
        [CurrencySymbol.EURO]: 'EUR'
      }

      return currencyMap[JSON.parse(sessionStorage.getItem('productPackageQuery'))?.currencySymbol] || 'USD'
    },
    priceLists () {
      return this.billwerkFeature.priceLists
    },

    // Only used for  BOTTIMMO NOT FOR MARKERO
    planVariants () {
      return this.billwerkFeature.planVariants
    },

    planPackages () {
      return this.billwerkFeature.packages
    },

    isAbortPaymentCallback () {
      return window.location.search.includes('trigger=Abort')
    },
    billwerkFeature () {
      return this.$features.feature(this.featureNames.BILLWERK)?.config
    },
    paymentMethods () {
      const { config } = this.$features.feature(this.featureNames.BILLWERK)
      return config.paymentMethods || [FALLBACK_PAYMENT_METHOD]
    }
  },

  watch: {
    /**
     * This is called when the SubscriptionJS library is loaded.
     * @param {Boolean} isInitialized Is defined in subscriptionJsMixin.
     */
    isSubscriptionJsInitialized (isInitialized) {
      if (isInitialized) {
        this.signupService = new this.SubscriptionJS.Signup()

        if (this.isProductTypeBeginner) {
          this.createOrder()
          return
        }

        this.getPreviewOrder()

        if (this.isState('finalize')) {
          this.finalize()
        }
      }
    },

    state (newState) {
      if (newState === 'completed') {
        this.$emit('completed')
      } else if (newState.match(/\.error$/)) {
        scrollIntoViewWithOffset(this.$refs.formSection, 60)
      }
    },

    billingSelfServicePortalDetails (billingSelfServicePortalDetails) {
      if (billingSelfServicePortalDetails?.Token) {
        this.portalService = new this.SubscriptionJS.Portal(this.billingSelfServicePortalDetails.Token)
      }
    },

    couponCode () {
      this.getPreviewOrder()
    },

    readableIds () {
      if (this.isPlatformMarkero) {
        this.handlePriceListIdChange(this.getPriceListId())
        this.handlePlanVariantIdChange(this.getPlanPackageId())
        this.handleComponentSubscriptionsChange(this.getComponentSubscriptions())
        this.getPreviewOrder()
      }
    }
  },
  async created () {
    if (this.isCallbackAfterPayment()) {
      this.setState('finalize')
      this.isYearly = localStorage.getItem('registration.billing.isYearly') === 'true'
    }

    if (this.isPlatformMarkero) {
      this.handlePriceListIdChange(this.getPriceListId())
      this.handlePlanVariantIdChange(this.getPlanPackageId())
      this.handleComponentSubscriptionsChange(this.getComponentSubscriptions())
    }
  },

  apollo: {
    customer: {
      query: GET_ONBOARDING_BILLING_DATA,
      update (data) {
        const {
          customer: {
            companyName, firstName, lastName, vatId,
            address: { street, houseNumber, postalCode, city, country }
          }
        } = data.onboardingBillingData
        return {
          emailAddress: this.email,
          companyName: companyName || this.customer.companyName,
          firstName: firstName || this.customer.firstName,
          lastName: lastName || this.customer.lastName,
          vatId: vatId || this.customer.vatId,
          paymentMethod: this.paymentMethods[0],
          address: {
            street: street || this.customer.address.street,
            houseNumber: houseNumber || this.customer.address.houseNumber,
            postalCode: postalCode || this.customer.address.postalCode,
            city: city || this.customer.address.city,
            country: country?.toUpperCase() || this.customer.address.country
          }
        }
      }
    },
    billingSelfServicePortalDetails: {
      query: SELF_SERVICE_PORTAL_URL,
      skip () {
        return !this.isFormOnly
      }
    }
  },

  methods: {
    isCallbackAfterPayment () {
      return this.$route.query[PAYMENT_CALLBACK_PARAM]
    },

    handlePriceListIdChange (priceListId) {
      this.cart.priceListId = priceListId
    },

    handlePlanVariantIdChange (planVariantId) {
      this.cart.planVariantId = planVariantId
    },

    handleComponentSubscriptionsChange (subscriptions) {
      this.cart.componentSubscriptions = subscriptions
    },

    getPriceListId () {
      return this.priceLists[this.currency]?.id ?? this.priceLists.default.id
    },

    getPlanPackageId () {
      return this.planPackages.find(planPackage => this.readableIds.includes(planPackage.readableId)).billwerkId
    },

    getComponentSubscriptions () {
      return this.planPackages
        .filter(item => this.readableIds.includes(item.readableId) && item.components?.length)
        .flatMap(item => item.components.filter(component => this.readableIds.includes(component.readableId)))
        .map(component => ({
          ComponentId: component.billwerkId,
          Quantity: 1
        }))
    },

    submitCustomerForm () {
      this.$refs.customerForm.submit()
    },

    async handleCustomerFormSubmit () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.SHOWN, 'Spinner Account Creation')
      this.setState('customerForm.submitting')

      try {
        await this.saveBillingData()
      } catch (error) {
        this.setState('customerForm.error')
        // this.errorCode = error.code // TODO
        // eslint-disable-next-line no-console
        console.log('mutation error', error)
        return // order must not be created if we couldn't process the customer data
      }

      this.createOrder()
    },

    async saveBillingData () {
      let result
      const input = {
        customer: {
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          vatId: this.customer.vatId,
          companyName: this.customer.companyName,
          address: {
            street: this.customer.address.street,
            houseNumber: this.customer.address.houseNumber,
            zip: this.customer.address.postalCode,
            city: this.customer.address.city,
            countryCode: this.customer.address.country.toLowerCase()
          }
        }
      }

      try {
        const { data } = await this.$apollo.mutate({
          mutation: HANDLE_ONBOARDING_BILLING_DATA,
          variables: { input }
        })
        result = data.result
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('graphql-error', err)
        throw new Error('graphql-error')
      }

      if (result.status === 'ERROR') {
        this.setState('customerForm.error')
      }
    },

    /**
     * This is step 1 of the subscribe process. It creates an order in Billwerk
     * which will be submitted with the payment process in second stage.
     */

    getCard () {
      return {
        ...this.cart,
        couponCode: this.customer.couponCode || this.couponCode
      }
    },

    getCustomer () {
      // isCompany is undefined if selection is not enabled, so we default to true
      const { companyName, vatId, isCompany = true, ...customerData } = this.customer
      return {
        ...customerData,
        companyName: companyName,
        // SubscriptionJS modifies the address object by reference
        // which causes empty form fields resulting in visual glitches.
        address: {
          ...this.customer.address
        },
        // Billwerk doesn't accept VAT ID with suffix;
        // Also we allow spaces in the VAT ID field but need to remove them here.
        ...(vatId && isCompany && { vatId: vatId.replace(/\s/g, '').replace(/[a-zA-Z]+$/, '').trim() }),
        locale: this.getCustomerLocale()
      }
    },

    createOrder () {
      const cart = this.getCard()
      const customer = this.getCustomer()

      if (this.billingOnboardingDetailsData?.isSignupComplete) {
        this.portalService.customerChange(
          customer,
          this.handleCustomerDataChanged,
          this.handleOrderError
        )
      } else {
        this.signupService.createOrder(
          cart,
          customer,
          this.handleOrderCreated,
          this.handleOrderError
        )
      }
    },

    /**
     * Success handler for `this.signupService.createOrder()`
     *
     * When the order is created, we need to trigger different payment processes
     * depending on the selected payment method.
     */
    async handleOrderCreated (order) {
      this.order = order

      try {
        await this.saveOrderId(order.OrderId)
      } catch (error) {
        this.setState('customerForm.error')
        // this.errorCode = error.code // TODO
        // eslint-disable-next-line no-console
        console.log('mutation error', error)
        return // order must not be created if we couldn't process the customer data
      }

      /*   if (this.isPlatformMarkero && this.isProductTypeBeginner) {
        this.setState('finalize')
        return
      } */

      this.startPaymentProcess()
    },

    /**
     * Success handler for `this.portal.customerChange()`
     *
     * When the customer data is changed, we need to trigger different payment processes
     * depending on the selected payment method.
     */
    async handleCustomerDataChanged () {
      const { data: { getOrderForCompany: order } } = await this.$apollo.query({
        query: GET_ORDER_FOR_COMPANY,
        variables: {
          companyId: this.$auth.user.companyId
        }
      })
      this.order = order
      if (!this.hasPaymentProvider) this.startPaymentProcess()
      else this.setState('completed')
    },

    /**
     * Error handler for `this.signupService.createOrder()`
     */
    handleOrderError (error) {
      this.setState('customerForm.error')
      // eslint-disable-next-line no-console
      console.error('orderError', error)

      if (
        error.errorCode.includes('InvalidCouponCode') ||
        error.errorCode.includes('InactiveCouponCode') ||
        error.errorCode.includes('CouponAlreadyUsed') ||
        error.errorCode.includes('IncompatibleCouponCode')
      ) {
        this.errorCode = 'INVALID_COUPON_CODE'
      } else if (error.errorMessage.includes('VAT ID')) {
        this.errorCode = 'INVALID_VAT_ID'
      } else {
        this.errorCode = error.errorCode[0]
      }
    },

    /**
     * We save the order ID in the database to be able to identify the order later.
     */
    async saveOrderId (orderId) {
      let result

      try {
        const { data } = await this.$apollo.mutate({
          mutation: ASSIGN_ONBOARDING_BILLING_ORDER_ID,
          variables: {
            input: { orderId }
          }
        })
        result = data.result
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('graphql-error', err)
        throw new Error('graphql-error')
      }

      if (result.status === 'ERROR') {
        this.setState('customerForm.error')
      }
    },

    /**
     * This is step 2 of the subscribe process. It initiates the payment process
     * for credit card payments.
     */
    startPaymentProcess () {
      const paymentConfig = {
        publicApiKey: process.env.VUE_APP_BILLWERK_PUBLIC_API_KEY,
        providerReturnUrl: this.composePaymentRedirectUrl()
      }
      const paymentData = {
        bearer: this.isProductTypeBeginner ? 'None:None' : this.customer.paymentMethod
      }

      if (this.isPlatformMarkero) {
        this.setToSessionStorage()
      }
      const onPaymentServiceReady = () => {
        if (this.billingOnboardingDetailsData?.isSignupComplete) {
          // We need this markero check because for bottimmo we already have a billwerk company and just want add the payment method
          if (this.hasActivePackage && this.isPlatformMarkero) {
            this.portalService.upgradePayInteractive(
              this.paymentService,
              paymentData,
              this.handlePaymentChangedSuccess,
              () => {
                this.setState('customerForm.error')
              }
            )
          } else {
            this.portalService.paymentChange(
              this.paymentService,
              paymentData,
              this.handlePaymentChangedSuccess,
              () => {
                this.setState('customerForm.error')
              }
            )
          }
        } else {
          this.signupService.paySignupInteractive(
            this.paymentService,
            paymentData,
            this.order,
            this.handlePaySignupInteractiveSuccess,
            () => {
              this.setState('customerForm.error')
            }
          )
        }
      }

      const onError = (error) => {
        this.setState('customerForm.error')
        // eslint-disable-next-line no-console
        console.error('Payment service init error', error)
      }

      this.paymentService = new this.SubscriptionJS.Payment(
        paymentConfig,
        onPaymentServiceReady,
        onError
      )
    },

    /**
     * Success handler for `this.portalService.paymentChange()`
     *
     * Depending on the chosen payment method we need to handle the following
     * process differently:
     * - For Credit Card we forward the user to the payment page to complete the payment.
     * - For debit the process is completed at this point.
     */
    handlePaymentChangedSuccess (data) {
      if (data.Url) {
        window.location.href = data.Url
      } else {
        if (this.hasActivePackage) {
          const type = this.isProductTypeBeginner ? this.$tracking.trackingEvents.UPGRADED : this.$tracking.trackingEvents.DOWNGRADED
          this.$tracking.event('Plan', type, this.productType)
        }
        /* eslint-disable-next-line no-unused-expressions */
        this.$gtm?.trackEvent({ event: trackingEvents.ONBOARDING_PAYMENT_SUCCEEDED })
        this.setState('completed')
      }
    },

    /**
     * Success handler for `this.signupService.paySignupInteractive()`
     *
     * Depending on the chosen payment method we need to handle the following
     * process differently:
     * - For Credit Card we forward the user to the payment page to complete the payment.
     * - For debit the process is completed at this point.
     */
    handlePaySignupInteractiveSuccess (data) {
      if (data.Url) {
        window.location.href = data.Url
      } else if (this.isProductTypeBeginner) {
        this.setState('finalize')
      } else {
        /* eslint-disable-next-line no-unused-expressions */
        this.$gtm?.trackEvent({ event: trackingEvents.LIGHT_PRODUCT_REGISTRATION_SUCCEEDED })
        this.setState('completed')
      }
    },

    /**
     * When the user returns from the payment page we need to finalize the
     * subscription process.
     * It could even be the case that the user aborted the payment process.
     * Even then we need to call `finalize()` to clean up the subscription process.
     */
    finalize () {
      this.SubscriptionJS.finalize(
        this.handleFinalizeSuccess,
        this.handleFinalizeError
      )
    },

    /**
     * Success handler for `this.SubscriptionJS.finalize()`
     */
    async handleFinalizeSuccess () {
      this.$tracking.event(trackingEvents.SUBSCRIPTION, this.$tracking.trackingEvents.CLICKED)
    },

    /**
     * Success handler when Loading component is finished
     */
    handleCompletedLoading () {
      /* eslint-disable-next-line no-unused-expressions */
      this.$gtm?.trackEvent({ event: trackingEvents.LIGHT_PRODUCT_REGISTRATION_SUCCEEDED })
      this.setState('completed')

      // We want all query params to be removed in the URL
      this.$router.replace(this.$route.name)
    },

    /**
     * Error handler for `this.SubscriptionJS.finalize()`
     */
    handleFinalizeError (error) {
      this.responseTimeout = setTimeout(() => {
        if (error.errorCode.includes('Canceled')) {
          this.setState('customerForm.idle')
          // We want all query params to be removed in the URL
          this.$router.replace(this.$route.name)
        } else {
          this.setState('customerForm.error')
        }
      }, 3000)
    },

    /**
     * Callback URL where the user is redirected to after the payment process.
     */
    composePaymentRedirectUrl () {
      const currentPath = this.$route.path
      const url = new URL(currentPath, window.location.origin)
      url.searchParams.set(PAYMENT_CALLBACK_PARAM, '1')
      return url.toString()
    },

    handleBookableProductSelected ({ productName, isYearly, skipPaymentInformation = false }) {
      if (productName === ProductType.LIGHT) {
        this.$gtm.trackEvent({ event: trackingEvents.LIGHT_PRODUCT_REGISTRATION_SUCCEEDED })
      }
      this.$gtm.trackEvent({
        event: trackingEvents.PRODUCT_REGISTRATION_SUCCEEDED,
        value: { productName, isYearly }
      })

      if (skipPaymentInformation) {
        this.setState('completed')
      } else {
        this.productName = productName
        this.isYearly = isYearly
        this.setState('customerForm.idle')
      }
    },

    getCustomerLocale () {
      return `de-${this.customer.address.country.toUpperCase()}`
    },

    buildAddress () {
      if (this.isProductTypeBeginner) return
      const address = this.customer.address
      return {
        street: address?.street,
        zip: address?.postalCode,
        city: address?.city,
        country: address.country?.toLowerCase()
      }
    },

    setToSessionStorage () {
      const productPackage = getProductPackageQuery()
      productPackage.address = this.buildAddress()
      productPackage.companyName = this.customer.companyName
      sessionStorage.setItem('productPackageQuery', JSON.stringify(
        productPackage
      ))
    },

    getPreviewOrder () {
      if (!this.signupService) {
        return
      }
      const cart = this.getCard()
      const customer = this.getCustomer()
      if (!cart.planVariantId || !cart.priceListId) {
        return
      }
      this.signupService.preview(
        cart,
        customer,
        this.handlePreviewOrderCreated,
        this.handlePreviewOrderError
      )
    },

    handlePreviewOrderCreated (preview) {
      const { Order } = preview
      this.previewOrder = {
        currency: Order.Currency,
        totalAfterVat: Order.TotalGross,
        payPeriod: Order.RecurringFee.FeePeriod.Quantity,
        coupon: Order.Coupon,
        isCouponValid: !Order.Coupon?.ErrorCode,
        couponRules: [
          !Order.Coupon?.ErrorCode || this.$t('billwerk.customer-form.preview-order.invalid-coupon-code')
        ],
        billingPeriods: Order.RecurringFee.LineItems.length > 0 ? Order.RecurringFee.LineItems
          : [{
            Id: Order.PriceListId,
            TotalNet: Order.Total,
            TotalVat: Order.TotalVat,
            PeriodMultiplier: 1
          }]
      }
      this.$emit('previewOrder', this.previewOrder)
    },
    handlePreviewOrderError (error) {
      // eslint-disable-next-line no-console
      console.log('preview order error', error)
    }
  }
}
</script>

<style scoped>
.info-text{
  max-width: 600px;
}
</style>
